<template>
  <v-col cols="5">
    <v-row>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          Фактурная стоимость
          <field-menu>
            <v-list-item @click="fillPriceWithWareDetailsCost">
              Пересчитать по стоимости товаров в списке
            </v-list-item>
            <v-list-item @click="fillPriceWithWareDetailsCostAllWares">
              Пересчитать по стоимости товаров в списке во всех товарах
            </v-list-item>
          </field-menu>
        </label>
        <input-numeric
          v-model="item.price"
          :fraction="2"
          :suffix="selected.about_wares.invoice_currency_letter"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onPriceChange"
        />
      </v-col>
      <v-col cols="8">
        <cost-details
          no-data-title="Отсутствуют включения в фактурную стоимость"
          :items="item.invoice_cost_includings_json"
        />
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>42 Цена товара</label>
        <input-numeric
          v-model="item.invoice_cost"
          :suffix="selected.about_wares.invoice_currency_letter"
          :fraction="2"
          disabled
          background-color="grey lighten-2"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="4"
        class="grey--text d-flex align-end"
      >
        <p
          v-if="currencyRate"
          class="mb-3"
        >
          {{ currencyRate.currency_scale }} : {{ currencyRate.rate }}
        </p>
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          43 МОС
          <field-menu>
            <v-list-item @click="copyToAll('customs_cost_method', null)">
              Очистить во всех товарах
            </v-list-item>
          </field-menu>
          <v-autocomplete
            v-model="item.customs_cost_method"
            :disabled="true"
            auto-select-first
            :items="costMethods"
            item-text="text"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            :background-color="isExport ? 'grey lighten-2' : 'white'"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          Основа ТС
          <input-numeric
            :value="customsCostBase"
            :fraction="2"
            disabled
            suffix="BYN"
            outlined
            dense
            hide-details="auto"
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="8"
      >
        <cost-details
          :items="item.customs_cost_includings_json"
          no-data-title="Отсутствуют включения в таможенную стоимость"
        />
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>45 Тамож. стоимость
          <field-menu>
            <v-list-item @click="clearCustomsCost">
              Очистить таможенную стоимость для всех товаров
            </v-list-item>
          </field-menu>
        </label>
        <input-numeric
          ref="customs_cost"
          v-model="item.customs_cost"
          :fraction="2"
          disabled
          suffix="BYN"
          outlined
          dense
          hide-details="auto"
          background-color="grey lighten-2"
        />
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          46 Стат. стоимость
          <input-numeric
            ref="statistical_cost"
            disabled
            :fraction="2"
            :value="item.statistical_cost"
            suffix="USD"
            outlined
            dense
            hide-details="auto"
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-self-end"
      >
        <v-text-field
          ref="customs_index"
          outlined
          dense
          :value="customsIndex"
          disabled
          suffix="$/кг"
          background-color="grey lighten-2"
          hide-details="auto"
        />
      </v-col>
      <v-col
        v-if="isExport"
        cols="12"
      >
        <cost-details
          no-data-title="Отсутствуют включения в статистическую стоимость"
          :items="item.statistical_cost_includings_json"
        />
      </v-col>
      <v-col cols="6">
        <label> Специфическая ед.изм.
          <field-menu>
            <v-list-item @click="copySpecificMeasureToAll">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copySpecificMeasure">
              Применить к выбранным товарам
            </v-list-item>
            <v-list-item
              :disabled="item.specific_measure_unit_digit !== $options.KG_DIGIT"
              @click="fillSpecificMeasureFromWeight"
            >
              Заполнить из веса для выбранных товаров
            </v-list-item>
            <v-list-item
              :disabled="!item.specific_measure_unit_digit"
              @click="fillSpecificMeasure"
            >
              Заполнить из доп.единицы для выбранных товаров
            </v-list-item>
          </field-menu>
        </label>
        <div class="d-flex mb-2">
          <input-numeric
            ref="specific_measure_unit_quantity"
            v-model="item.specific_measure_unit_quantity"
            style="width: 50%;"
            :fraction="2"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
          <v-autocomplete
            ref="specific_measure_unit_digit"
            v-model="item.specific_measure_unit_digit"
            auto-select-first
            item-text="shortDescription"
            item-value="code"
            :items="measureUnits"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
          />
        </div>
      </v-col>
      <v-col cols="6">
        <label>Подакцизный товар
          <field-menu>
            <v-list-item @click="copyExciseMeasureToAll">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copyExciseMeasure">
              Применить к выбранным товарам
            </v-list-item>
            <v-list-item
              :disabled="!isFillQuantityAvailable"
              @click="fillExciseWithNetWeight"
            >
              Заполнить поле подакцизный товар
            </v-list-item>
          </field-menu>
        </label>
        <div class="d-flex mb-2">
          <input-numeric
            ref="excise_measure_unit_quantity"
            v-model="item.excise_measure_unit_quantity"
            :normalizer="exciseNormalizer"
            style="width: 50%;"
            :fraction="2"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
          <v-autocomplete
            ref="excise_measure_unit_digit"
            v-model="item.excise_measure_unit_digit"
            auto-select-first
            item-text="shortDescription"
            item-value="code"
            :items="measureUnits"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
            @change="normalizeExciseQuantity"
          />
        </div>
      </v-col>
    </v-row>
    <ware-calculated-payments
      v-if="item.ware_payment_json.length"
      :payments="warePaymentsPretty"
      @clear="clearPayments"
    />
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getMeasureUnitShortName, KG_DIGIT} from '@/helpers/catalogs'
import {
  tnvedCodeChanged,
  updateGoodParameters,
} from "@/events/statistics";
import {
  getWaresParamTotal,
  prettifyNumber,
  fetchCurrency,
  normalizeFloat, exciseQuantityAfterPoint
} from "@/helpers/math";
import cloneDeep from "lodash.clonedeep";
import formatDate from "@/mixins/format-date.js";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {
  addPromiseInjector,
  isOpenInjector,
  readyToUpdateInjector, showDynamicComponentInjector,
  uploadDataInjector
} from "@/helpers/injectors";
import moment from "moment/moment";
import WareCalculatedPayments from "@/components/documents/ware/ware-calculated-payments.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import CostDetails from "@/components/dt/goods/cost-details.vue";
import {defaultAntiDumpingsJson, defaultCustomsDutyJson, defaultExciseJson} from "@/components/dt/goods/payments/payments";
import {
  SPECIFIC_MEASURE_FROM_WEIGHT_TYPE,
  SPECIFIC_MEASURE_TYPE
} from "@/components/dt/goods/fill-with-utils";



export default {
  KG_DIGIT,
  components: {
    CostDetails, FieldMenu, WareCalculatedPayments, InputNumeric
  },
  mixins: [filterBySearchFieldMixin, formatDate, HighlightFieldWareSector, copyFieldMixin],
  inject: {
    readyToUpdate: {
      from: readyToUpdateInjector
    },
    addPromise: {
      from: addPromiseInjector
    },
    uploadData: {
      from: uploadDataInjector
    },
    isOpen:{
      from: isOpenInjector
    },
    showDynamicComponent:{
      from:showDynamicComponentInjector
    }
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      type: Object,
      required: true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    wareDetails: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    availableExcises: {},
    availableAntiDumpings: [],
    availableCustomsDuties: [],
    item: {
      id: null,
      invoice_cost: "",
      customs_cost: "",
      customs_cost_method: "",
      ware_payment_json: [],
      statistical_cost: null,
      net_weight: '',
      specific_measure_unit_digit: "",
      specific_measure_unit_letter: "",
      specific_measure_unit_quantity: "",
      excise_measure_unit_digit: "",
      excise_measure_unit_letter: "",
      excise_measure_unit_quantity: "",
      excise_json: defaultExciseJson(),
      antidumping_json: defaultAntiDumpingsJson(),
      customs_duty_json: defaultCustomsDutyJson(),
      customs_cost_includings_json: [],
      invoice_cost_includings_json: [],
      statistical_cost_includings_json:[],
      price: null,
    },
    currencyRate: null,
    customsCostBase: null,
  }),
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      currencies: "catalogs/getNsiCurrencies",
      selectedWares: "dt/getSelectedWares",
      costMethods: "catalogs/getNsiCustomsCostMethods",
      measureUnits: "catalogs/getNsiMeasureUnits",
      isImport40: "dt/isImport40",
      isExport: "dt/isExport"
    }),
    warePaymentsPretty() {
      return this.item.ware_payment_json.map((payment) => {
        const basisPretty = prettifyNumber(payment.basis)
        const sumPretty = payment.sum ? prettifyNumber(Number.parseFloat(payment.sum).toFixed(2)) : '0'
        return {
          ...payment,
          basisPretty,
          sumPretty
        }
      })
    },
    isFillQuantityAvailable() {
      return ["КГ", "Т"].includes(this.selectedExcise?.measureUnitLetter)
    },
    selectedAntiDumping() {
      return this.availableAntiDumpings.find(v => v.tarifId === this.item.antidumping_json.antidumping_id) ?? null
    },
    customsIndex() {
      const value = (this.item.statistical_cost / this.item.net_weight).toFixed(2)
      if (!isNaN(value) && this.item.statistical_cost && this.item.net_weight) {
        return `${value}`
      }
      return "0"
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    selectedWares: {
      handler() {
        const wareIdBeforeUpdate = this.item.id
        this.setFields();        
        this.updatePaymentsOptionsIfOpenAndIdChanged(wareIdBeforeUpdate)
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        if (this.visited) {          
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeChanged.subscribe(this.onNewCode);
    updateGoodParameters.subscribe(this.onParamsChange);
  },
  mounted() {
    this.watchCurrencyRateTriggers()
  },
  beforeDestroy() {
    tnvedCodeChanged.unsubscribe();
    updateGoodParameters.unsubscribe();
  },
  methods: {
    getNeedfulDate(){
      return this.selected.type_declaration.declaration_feature_date ?? this.selected.about_declaration.declaration_date
    },
    fillSpecificMeasure(){
      const params = {
        type: SPECIFIC_MEASURE_TYPE,
        filter:{
          measureUnitLetter:getMeasureUnitShortName(this.measureUnits, this.item.specific_measure_unit_digit)
        }
      }
      this.showDynamicComponent({
        params,
        component:() => import('@/components/dt/goods/fill-with-ware-details.vue')
      })
    },
    fillSpecificMeasureFromWeight(){
      const params = {
        type: SPECIFIC_MEASURE_FROM_WEIGHT_TYPE,
        filter:{
          measureUnitLetter:getMeasureUnitShortName(this.measureUnits, this.item.specific_measure_unit_digit)
        }
      }
      this.showDynamicComponent({
        params,
        component:() => import('@/components/dt/goods/fill-with-ware-details.vue')
      })
    },
    copySpecificMeasureToAll(){
      this.copyToAll([
        'specific_measure_unit_quantity',
        'specific_measure_unit_letter',
        'specific_measure_unit_digit'
      ], [
        this.item.specific_measure_unit_quantity,
        this.item.specific_measure_unit_letter,
        this.item.specific_measure_unit_digit
      ])
    },
    copySpecificMeasure(){
      this.copy([
        'specific_measure_unit_quantity',
        'specific_measure_unit_letter',
        'specific_measure_unit_digit'
      ], [
        this.item.specific_measure_unit_quantity,
        this.item.specific_measure_unit_letter,
        this.item.specific_measure_unit_digit
      ], null, 'apply', {
        specific_measure_unit_letter: this.item.specific_measure_unit_letter
      })
    },
    copyExciseMeasureToAll(){
      this.copyToAll([
        'excise_measure_unit_quantity',
        'excise_measure_unit_letter',
        'excise_measure_unit_digit'
      ], [
        this.item.excise_measure_unit_quantity,
        this.item.excise_measure_unit_letter,
        this.item.excise_measure_unit_digit
      ])
    },
    copyExciseMeasure(){
      this.copy([
        'excise_measure_unit_quantity',
        'excise_measure_unit_letter',
        'excise_measure_unit_digit'
      ], [
        this.item.excise_measure_unit_quantity,
        this.item.excise_measure_unit_letter,
        this.item.excise_measure_unit_digit
      ], 'apply',null, {
        excise_measure_unit_letter: this.item.excise_measure_unit_letter
      })
    },
    forceUpload() {
      return this.uploadData()
        .catch((err) => {
          this.$error('Не удалось сохранить товар');
          throw err
        })
    },
    fillExciseWithNetWeight() {
      this.forceUpload()
        .then(() => this.$store.dispatch('dt/fillExciseUnitQuantityWithNetWeight')
          .catch((err) => {
            this.$error('Не удалось обновить значения')
            throw err
          })
        )
        .then(() => this.$info("Значения обновлены"));
    },
    normalizeExciseQuantity() {
      if (!this.item.excise_measure_unit_quantity) return;
      this.item.excise_measure_unit_quantity = this.exciseNormalizer(this.item.excise_measure_unit_quantity)
    },
    exciseNormalizer(value) {
      const unitLetter = getMeasureUnitShortName(this.measureUnits, this.item.excise_measure_unit_digit)
      const afterPoint = exciseQuantityAfterPoint(value, unitLetter)
      return normalizeFloat({value, afterPoint})
    },
    fetchCurrency,
    clearCustomsCost() {
      this.copyToAll('customs_cost', null)
    },
    fetchCurrencyRate() {
      this.fetchCurrency({
        date: this.getNeedfulDate(),
        code: this.selected.about_wares.invoice_currency_letter
      }).then((currency) => {
        if (currency) {
          this.currencyRate = currency
        }
      })
    },

    fillPriceWithWareDetailsCostAllWares() {
      this.$store.dispatch('dt/recalculateInvoiceCostAllWares')
        .then(() => this.$info('Готово'))
        .catch(() => this.$error())
    },
    fillPriceWithWareDetailsCost() {
      this.item.price = getWaresParamTotal(this.wareDetails, 'invoice_cost')
      this.readyToUpdate()
    },
    onParamsChange({id, payload}) {
      if (id === this.item.id) {
        const {invoice_cost} = payload;
        this.item.invoice_cost = invoice_cost;
      }
    },
    onNewCode({id, code}) {
      if (id === this.item.id) {
        this.updatePaymentsOptions(code);
        this.updateSpecificMeasure(code)
      }
    },

    isEmptyCodeAndTnved(code) {
      return !code && !this.info.tn_ved
    },
    
    getDeclarationDate() {
      const dateFormat = 'DD.MM.YYYY'
      return moment(this.selected.about_declaration.declaration_date, 'YYYY-MM-DD').isValid()
        ? moment(this.selected.about_declaration.declaration_date, 'YYYY-MM-DD').format(dateFormat)
        : moment().format(dateFormat)
    },
    onPriceChange() {
      this.updateInvoiceCost()
    },
    updateInvoiceCost() {
      this.item.invoice_cost = this.item.price
    },
    updateSpecificMeasure(code) {
      if (code) {
        const promise = this.$store.dispatch("tnved/fetchSpecificMeasureUnit", code)
          .then((response) => {
            const {code, short_description} = response.data;
            this.item.specific_measure_unit_digit = code;
            this.item.specific_measure_unit_letter = short_description;
          })
        this.addPromise(promise);
      } else {
        this.item.specific_measure_unit_quantity = null
      }
    },

    clearPayments() {
      this.item.ware_payment_json = [];
      this.readyToUpdate();
    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
      
      this.clearCustomCostAndStatCost();
    },
    updateEditableGoodsItem() {
      this.readyToUpdate();
      const payload = {
        ...this.item,
        specific_measure_unit_letter: getMeasureUnitShortName(this.measureUnits, this.item.specific_measure_unit_digit),
        excise_measure_unit_letter: getMeasureUnitShortName(this.measureUnits, this.item.excise_measure_unit_digit)
      }
      this.$emit("update", payload);
    },
    clearCustomCostAndStatCost() {
      if (this.item.customs_cost != null || this.item.statistical_cost != null) {
        this.item.customs_cost = null
        this.item.statistical_cost = null
        this.readyToUpdate();  
      }   
    },
    watchCurrencyRateTriggers() {
      this.$watch((vm) => [
        vm.selected.about_declaration.declaration_date,
        vm.selected.about_wares.invoice_currency_letter
      ], () => this.fetchCurrencyRate(), {
        immediate: true
      })
    },
    updatePaymentsOptionsIfOpenAndIdChanged(wareIdBeforeUpdate){
      if(this.isOpen() && wareIdBeforeUpdate !== this.item.id){
        this.preloadAvailablePaymentsOptions()
      }
    },
    preloadAvailablePaymentsOptions() {
      if (this.isEmptyCodeAndTnved() || this.isExport) return;
    },
  },
};
</script>
